<template>
  <base-section id="about-our-product">
    <base-section-heading
      color="grey lighten-2"
      icon="mdi-silverware-variant"
      title="CONSERVAS DE PESCADO EMPRESA DE CONSERVAS CANTDELIMAR"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Valoramos',
          subtitle: 'Todo lo que Cantdelimar puede ofrecer',
          text: 'La calidad de todos sus productos, el servicio a sus clientes y una elaboración artesana son las características que diferencian a Cantdelimar como una empresa sólida que apuesta por ofrecer un producto que destaque por su incomparable sabor y calidad, así como por una excelente presentación.',
          callout: '01',
        },
        {
          title: 'Conservas de pescado en Cantabria',
          subtitle: 'Nivel nacional',
          text: 'Se realiza a nivel nacional a lo largo de la costa norte. A través de una cadena de distribución sólida se cumplen los plazos de envío para la satisfacción del cliente.',
          callout: '02',
        },
        {
          title: 'Nuestra prioridad',
          subtitle: 'El cliente',
          text: 'A lo largo de los ultimos años, Cantdelimar se ha dedicado a crecer como empresa haciendo acople de las necesidades de sus clientes y una mejora constante de sus productos. Todo esto se aborda de una forma inovadora y creativa, empleando toda la profesionalidad y dedicación que está en su mano.',
          callout: '03',
        },
      ],
    }),
  }
</script>
